import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import useWindowEvent from 'src/js/utils/hooks/useWindowEvent'

// Styles & Images:
import 'src/components/carousel/AppsCarousel/scss/styles.scss'

// Partials:
const Arrow = ({ type, title, disabled, onClick }) => {
  const iconTypeProps =
    type === 'left'
      ? {
          transform: 'translate(5 7) scale(-1 1) rotate(-90) translate(-5 -7)',
          points: '11 4 5 10 -1 4',
        }
      : {
          transform: 'translate(4 7) rotate(-90) translate(-4 -7)',
          points: '10 4 4 10 -2 4',
        }

  return (
    <span
      className={classNames('x-carousel__controls', `x-carousel__controls-${type}`, {
        'x-carousel__controls--disabled': disabled,
      })}
      onClick={onClick}
    >
      <a title={title} role="button">
        <svg version="1.1" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
          <title>{title}</title>
          <polyline {...iconTypeProps} stroke="#fff" strokeWidth="2" fill="none" />
        </svg>
      </a>
    </span>
  )
}

const Item = ({ data, isActive, isPast }) => (
  <div
    className={classNames('x-carousel__item', {
      'x-carousel__item--active': isActive,
      'x-carousel__item--past': isPast,
    })}
  >
    {data}
  </div>
)

// Main component:
const Carousel = ({
  children,
  className,
  id,
  interval = 7000,
  captions = [],
  hasBackdrop = true,
  hasIndicators = true,
  disableArrows = false,
  disablePeriod,
  onChange,
}) => {
  const wrapperElementRef = useRef()
  const timeoutRef = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const maxIndex = children.length - 1
  const [isMobile, setIsMobile] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [touchStartX, setTouchStartX] = useState(0)
  const [touchEndX, setTouchEndX] = useState(0)

  useEffect(() => {
    if (!onChange) return

    onChange(activeIndex)
  }, [activeIndex])

  // Disabled period.
  useEffect(() => {
    if (!disablePeriod) return

    setDisabled(true)

    const timeout = setTimeout(() => {
      setDisabled(false)
    }, disablePeriod)

    return () => clearTimeout(timeout)
  }, [activeIndex])

  // handle item change.
  const handlePrevClick = () => {
    if (disabled) return

    const newIndex = activeIndex - 1
    setActiveIndex(newIndex >= 0 ? newIndex : maxIndex)
  }

  const handleNextClick = () => {
    if (disabled) return

    const newIndex = activeIndex + 1
    setActiveIndex(newIndex <= maxIndex ? newIndex : 0)
  }

  const handleIndicatorClick = (index) => {
    if (disabled) return

    setActiveIndex(index)
  }

  // media-queries.
  const checkIsMobile = () => {
    if (typeof window === 'undefined') return
    setIsMobile(window.innerWidth < 991)
  }

  useWindowEvent('resize', checkIsMobile)
  useEffect(checkIsMobile, [])

  // auto-play.
  const resetTimeout = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
  }

  useEffect(() => {
    resetTimeout()
    timeoutRef.current = setTimeout(() => handleNextClick(), interval)

    return () => resetTimeout()
  }, [activeIndex])

  // swipeable / touch events.
  const handleTouchStart = (e) => {
    setTouchStartX(e.changedTouches[0].screenX)
  }

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].screenX)

    if (!touchStartX || !touchEndX) return

    const MIN_SWIPE_DISTANCE = 24
    const swipeDistance = touchStartX - touchEndX

    if (swipeDistance > MIN_SWIPE_DISTANCE) handleNextClick()
    if (swipeDistance < -MIN_SWIPE_DISTANCE) handlePrevClick()
  }

  // control elements.
  const leftArrow = (
    <Arrow to={id} type="left" title="Previous" disabled={disabled} onClick={handlePrevClick} />
  )

  const rightArrow = (
    <Arrow to={id} type="right" title="Next" disabled={disabled} onClick={handleNextClick} />
  )

  // indicator / dot navigation elements.
  const indicators = (
    <ol className="x-carousel__indicators">
      {children.map((item, index) => (
        <li
          key={index}
          className={classNames('x-carousel__indicators__indicator', {
            'x-carousel__indicators__indicator--active': index === activeIndex,
          })}
          onClick={() => handleIndicatorClick(index)}
        />
      ))}
    </ol>
  )

  // caption elements.
  const captionElements = captions.map((caption, index) => {
    return (
      index === activeIndex && (
        <div key={index} className="x-carousel__caption x-carousel__caption--active">
          <span class="x-carousel__caption__numbers">{`${activeIndex + 1}/${maxIndex + 1}`}</span>
          <span class="x-carousel__caption__title">{caption.title}</span>
          <span class="x-carousel__caption__description">{caption.description}</span>
        </div>
      )
    )
  })

  // if empty.
  if (!children) return null

  return (
    <div
      id={id}
      className={classNames('x-carousel', className, {
        'x-carousel--backdrop': hasBackdrop,
        'x-carousel--indicators': hasIndicators,
      })}
      ref={wrapperElementRef}
    >
      {!isMobile && !!captions.length && captionElements}

      {!isMobile && !disableArrows && leftArrow}

      <div
        className="x-carousel__items"
        style={{
          left: `calc(100% * ${activeIndex * -1})`,
          width: `calc(100% * ${maxIndex + 1})`,
        }}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        {children.map((item, index) => (
          <Item
            key={index}
            data={item}
            isActive={index === activeIndex}
            isPast={index === activeIndex - 1}
          />
        ))}
      </div>

      {!isMobile && !disableArrows && rightArrow}

      {hasIndicators && indicators}
    </div>
  )
}

export default Carousel
